import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./app.css";

const h = createApp(App);
window.$ = window.jQuery = require("jquery");

import Vue3TouchEvents from "vue3-touch-events";

//Animation directive
h.directive("ani", {
  // When the bound element is inserted into the DOM...
  mounted: function (el, binding) {
    el.style.visibility = "hidden";
    let check = function () {
      if (isVisible(el)) {
        setTimeout(() => {
          el.classList.add(binding.value.class);
          el.style.visibility = "visible";
        }, binding.value.delay);
      }
    };

    let isVisible = function (el) {
      var rect = el.getBoundingClientRect();
      return rect.top - window.innerHeight < 0;
    };
    window.addEventListener("scroll", check);
    setInterval(check, 100);
  },
});

h.use(store).use(router).use(Vue3TouchEvents).mount("#app");
