<template>
  <div v-if="!loaded">
    <Loader></Loader>
  </div>

  <transition name="fi">
    <router-view v-if="ready" />
  </transition>
  <transition name="fi">
    <div
      class="h-screen w-screen flex items-center justify-center overflow-hidden"
      v-show="loaded && !ready"
    >
      <div
        class="absolute top-0 left-0 w-screen h-screen object-cover"
        ref="lottie"
      ></div>
      <!--
      <video
        id="hv"
        class="absolute top-0 left-0 w-screen h-screen object-cover"
        autoplay
        muted
        ref="v"
      >
        <source
          src="https://gateway.pinata.cloud/ipfs/QmPKwc5oDwERAXmiNqFTyAUaa7cMX33DHG6CnHPYTfWoJf"
          type="video/mp4"
        />
      </video> -->
    </div>
  </transition>
</template>
<script>
import Loader from "./components/Loader.vue";
export default {
  data() {
    return {
      ready: false,
      lottieLoaded: false,
    };
  },
  components: {
    Loader,
  },
  watch: {
    loaded() {
      console.log("change!");
    },
  },
  mounted() {
    this.$store.dispatch("loadData");
    //  this.$refs.v.onended = () => {
    //    this.ready = true;
    //  };

    var lot = window.lottie.loadAnimation({
      container: this.$refs.lottie, // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: "/hw30img/data.json", // the path to the animation json
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });

    lot.addEventListener("DOMLoaded", () => {
      console.log("loaded lottie!");
      this.lottieLoaded = true;
      lot.play();
    });

    lot.addEventListener("complete", () => {
      console.log("complete lottie!");
      this.ready = true;
    });
  },
  computed: {
    loaded() {
      if (this.$store.state.timeline.length && this.lottieLoaded) return true;
      return false;
    },
  },
};
</script>
<style lang="scss"></style>
