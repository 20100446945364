import { createStore } from "vuex";

import axios from "axios";
export default createStore({
  state: {
    master: {},
    timeline: [],
    isModalActive: false,
    isFilterModalActive: false,
    activeEntry: {},
    artists: [],
    artistsFilter: [],
    locations: [],
    locationsFilter: [],
    yindex: null,
    index: null,
    fullscreen: false,
    selectedIndex: 0,
    selectedYear: false,
  },
  getters: {
    timestream: (state) => {
      var time = state.timeline.filter((entry) => {
        //Artist Filter
        if (state.artistsFilter.length) {
          if (!entry.artists[0]) return false;
          if (state.artistsFilter[0].slug != entry.artists[0].slug)
            return false;
        }

        //Locations Filter
        if (state.locationsFilter.length) {
          if (!entry.locations[0]) return false;
          if (state.locationsFilter[0].slug != entry.locations[0].slug)
            return false;
        }

        return true;
      });

      //Categorize Timestream by Year
      var t = {};
      time.forEach((element) => {
        if (!t[element.date[2]])
          t[element.date[2]] = { showCount: 2, entries: [] };
        t[element.date[2]].entries.push(element);
      });

      //Sort Entries by Discover/Date
      Object.entries(t).forEach((element) => {
        t[element[0]].entries.sort((a, b) => {
          if (!a.discover && b.discover) return 1;
          if (a.discover && !b.discover) return -1;
          if (a.discover == b.discover){
              if(parseInt(a.date[1]) < parseInt(b.date[1])) return -1;
              if(parseInt(b.date[1]) < parseInt(a.date[1])) return 1;
              if(parseInt(a.date[1]) == parseInt(b.date[1])){
                if(parseInt(a.date[0]) < parseInt(b.date[0])) return -1;
                if(parseInt(b.date[0]) < parseInt(a.date[0])) return 1;
              }
          }
          return 0;
        });

        //Set ShowCount
        var showCount = element[1].entries.filter((obj)=>{
          if(obj.discover) return true;
          return false;
        }).length;
        if(showCount <= 0) showCount = 1;
        t[element[0]].showCount = showCount;

      });
      console.log(t);
      return t;
    },
    isModalActive: (state) => {
      return state.isModalActive;
    },
    isFilterModalActive: (state) => {
      return state.isFilterModalActive;
    },
    activeEntry: (state) => {
      return state.activeEntry;
    },
    artists: (state) => {
      return state.artists;
    },
    artistsFilter: (state) => {
      return state.artistsFilter;
    },
    locations: (state) => {
      return state.locations;
    },
    locationsFilter: (state) => {
      return state.locationsFilter;
    },
    yindex: (state) => {
      return state.yindex;
    },
    index: (state) => {
      return state.index;
    },
    fullscreen: (state) => {
      return state.fullscreen;
    },
    selectedIndex: (state) => {
      return state.selectedIndex;
    },
  },
  mutations: {
    setMaster(state, master) {
      state.master = master;
    },
    setSelectedYear(state, selectedYear) {
      state.selectedYear = selectedYear;
    },
    setFullscreen(state, fullscreen) {
      state.fullscreen = fullscreen;
    },
    setSelectedIndex(state, selectedIndex) {
      state.selectedIndex = selectedIndex;
    },
    setIndex(state, index) {
      state.index = index;
    },
    setYindex(state, yindex) {
      state.yindex = yindex;
    },
    viewAll(state) {
      state.artistsFilter = [];
      state.locationsFilter = [];
    },
    setTimeline(state, timeline) {
      state.timeline = timeline;
    },
    setModal(state, status) {
      state.isModalActive = status;
    },
    setFilterModal(state, status) {
      state.isFilterModalActive = status;
    },
    setActiveEntry(state, entry) {
      state.activeEntry = entry;
    },
    setArtists(state, artists) {
      state.artists = artists;
    },
    setArtistsFilter(state, artist) {
      state.artistsFilter.push(artist);
    },
    setLocations(state, locations) {
      state.locations = locations;
    },
    setLocationsFilter(state, location) {
      state.locationsFilter.push(location);
    },
  },
  actions: {
    async loadData({ commit }) {
      console.log("Loading..");
      var res = await Promise.all([
        axios.get("https://hw30secure1.wpengine.com/wp-json/hauser/v1/master"),
        axios.get(
          "https://hw30secure1.wpengine.com/wp-json/wp/v2/timeline/?per_page=500"
        ),
      ]);

      console.log("loaded!");

      //Map Entries Format
      var timeline = res[1].data.map((entry) => {
        var d = entry.acf;
        d.date = entry.acf.date.split(",");
        d.title = entry.title.rendered;
        d.artists = entry.artists;
        d.locations = entry.locations;
        return d;
      });

      var artists = [];
      var locations = [];

      //Process Timeline Entries
      timeline.forEach((element) => {
        //Populate Artists
        if (Array.isArray(element.artists)) {
          element.artists.forEach((artist) => {
            if (!artists.some((e) => e.term_id === artist.term_id))
              artists.push(artist);
          });
        }

        //Populate Locations
        if (Array.isArray(element.locations)) {
          element.locations.forEach((location) => {
            if (!locations.some((e) => e.term_id === location.term_id))
              locations.push(location);
          });
        }
      });

      commit("setArtists", artists);
      commit("setLocations", locations);
      commit("setTimeline", timeline);
      commit("setMaster", res[0].data);
    },
  },
  modules: {},
});
